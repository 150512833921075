// extracted by mini-css-extract-plugin
export var Certifications = "aboutDiverger-module--Certifications--IhqWs";
export var Clients_list = "aboutDiverger-module--Clients_list--xZvGU";
export var Clients_pretitle = "aboutDiverger-module--Clients_pretitle--QMRz8";
export var Clients_title = "aboutDiverger-module--Clients_title--1IdCv";
export var CompanyData = "aboutDiverger-module--CompanyData--RVN4+";
export var CompanyData_pretitle = "aboutDiverger-module--CompanyData_pretitle--ovrdE";
export var CompanyData_title = "aboutDiverger-module--CompanyData_title--xgB+2";
export var CompanyInfo_wrapper = "aboutDiverger-module--CompanyInfo_wrapper--9yYU6";
export var Info = "aboutDiverger-module--Info--nlAhM";
export var Info_list = "aboutDiverger-module--Info_list--nEF4N";
export var Info_title = "aboutDiverger-module--Info_title--LTaQR";
export var IntroColumns = "aboutDiverger-module--IntroColumns--siA0Q";
export var IntroColumns_description = "aboutDiverger-module--IntroColumns_description--iqbWV";
export var IntroColumns_title = "aboutDiverger-module--IntroColumns_title--xizrJ";
export var Number_digit = "aboutDiverger-module--Number_digit--RwUgd";
export var Number_text = "aboutDiverger-module--Number_text--PsQnz";
export var Numbers_grid = "aboutDiverger-module--Numbers_grid--2MiZd";
export var Slider = "aboutDiverger-module--Slider--qU6az";
export var Slider_wrapper = "aboutDiverger-module--Slider_wrapper--OJOec";
export var Tech = "aboutDiverger-module--Tech--UVpkD";
export var TechLogo = "aboutDiverger-module--TechLogo--x0Jb6";
export var TechLogo_img = "aboutDiverger-module--TechLogo_img--Mvi2o";
export var TechLogos = "aboutDiverger-module--TechLogos--5aznp";
export var TechLogos_grid = "aboutDiverger-module--TechLogos_grid--HwMQZ";
export var TechLogos_gridCol = "aboutDiverger-module--TechLogos_gridCol--nGOdA";
export var TechLogos_wrapper = "aboutDiverger-module--TechLogos_wrapper--sdJVX";
export var Tech_title = "aboutDiverger-module--Tech_title--ME8xL";
export var WorldMap = "aboutDiverger-module--WorldMap--b5Ncm";
export var l = "aboutDiverger-module--l--K6Rk9";
export var m = "aboutDiverger-module--m--Pdjpm";
export var s = "aboutDiverger-module--s--awhPz";
export var xl = "aboutDiverger-module--xl--JukTx";
export var xs = "aboutDiverger-module--xs--yMjdb";