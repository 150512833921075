import * as React from "react";
import * as style from "./styles/aboutDiverger.module.scss";
import { graphql } from "gatsby";
import uuid from "react-uuid";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import { getImage } from "gatsby-plugin-image";
import Masonry from "react-masonry-css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import { useMediaQuery } from "react-responsive";
import {
  mobileBreakpoint,
  DesktopView,
  NotDesktopView,
} from "components/Mediaqueries";
import PageWrapper from "components/PageWrapper";
import HeaderSection from "components/HeaderSection";
import BoundedSection from "components/BoundedSection";
import IntroSection from "components/IntroSection";
import Parallax from "components/Parallax";
import MorphingScrollImg from "components/MorphingScrollImg";

import JavaLogo from "../images/tech_logos/java.svg";
import JavaScriptLogo from "../images/tech_logos/javascript.svg";
import ReactLogo from "../images/tech_logos/react.svg";
import HtmlLogo from "../images/tech_logos/html.svg";
import CssLogo from "../images/tech_logos/css.svg";
import RailsLogo from "../images/tech_logos/rails.svg";
import DockerLogo from "../images/tech_logos/docker.svg";
import RestLogo from "../images/tech_logos/rest.svg";
import GraphQlLogo from "../images/tech_logos/graphql.svg";
import GRPCLogo from "../images/tech_logos/grpc.svg";
import KafkaLogo from "../images/tech_logos/kafka.svg";
import CucumberLogo from "../images/tech_logos/cucumber.svg";
import CypressLogo from "../images/tech_logos/cypress.svg";
import KubernetesLogo from "../images/tech_logos/kubernetes.svg";
import MongoDbLogo from "../images/tech_logos/mongodb.svg";
import MySqlLogo from "../images/tech_logos/mysql.svg";
import PostgreSQlLogo from "../images/tech_logos/postgresql.svg";
import AutoAwesomeTwoToneIcon from '@mui/icons-material/AutoAwesomeTwoTone';
import HandshakeTwoToneIcon from '@mui/icons-material/HandshakeTwoTone';
import BusinessTwoToneIcon from '@mui/icons-material/BusinessTwoTone';
import CodeTwoToneIcon from "@mui/icons-material/CodeTwoTone";
import WorldMap from "../images/misc/world-map.svg";
import PartnerPledgeLogo from "../images/certification_logos/partner-pledge.png";
import InnovativePyme from "../images/certification_logos/pyme-innovadora.png";
import Iso14001 from "../images/certification_logos/iso-14001.png";
import Iso9001 from "../images/certification_logos/iso-9001.png";

const slides = [
  {
    img: (data) => data && getImage(data.slider1),
    alt: "",
  },
  {
    img: (data) => data && getImage(data.slider2),
    alt: "",
  },
];

const Slider = ({ data }) => {
  const isMobile = useMediaQuery(mobileBreakpoint);
  return (
    <BoundedSection
      className={style.Slider}
      boundedClassName={style.Slider_wrapper}
      data-cursor-size="120px"
      data-cursor-color="#081832"
    >
      <Swiper
        enabled={isMobile}
        slidesPerView={isMobile ? 1.25 : 2}
        spaceBetween={isMobile ? 16 : 32}
        slidesPerGroup={isMobile ? 1 : 2}
        loop={true}
        loopFillGroupWithBlank={true}
        className="mySwiper"
      >
        {slides.map(({ img, alt }) => (
          <SwiperSlide key={uuid()}>
            <MorphingScrollImg img={img(data)} alt={alt} />
          </SwiperSlide>
        ))}
      </Swiper>
    </BoundedSection>
  );
};

const IntroColumns = ({ icon, title,  description }) => {
  return (
    <BoundedSection className={style.IntroColumns}>
      <h4 className={style.IntroColumns_title}>
        {icon}
        {title}
      </h4>
      <div className={style.IntroColumns_description}>{description}</div>
    </BoundedSection>
  );
};

const Info = ({ icon, title, list }) => {
  return (
    <div className={style.Info}>
      <h4 className={style.Info_title}>
        {icon}
        {title}
      </h4>
      <div className={style.Info_list}>
        {list}
      </div>
    </div>
  );
};

const Number = ({ digit, text }) => {
  return (
    <div>
      <div>
        <p className={style.Number_digit}>
          {digit}
        </p>
        <p className={style.Number_text}>
          {text}
        </p>
      </div>
    </div>
  );
};

const CompanyData = ({ t }) => (
  <BoundedSection className={style.CompanyData}>
    <h3 className={style.CompanyData_pretitle}>{t("aboutDiverger.companyData.preTitle")}</h3>
    <p className={style.CompanyData_title}>{t("aboutDiverger.companyData.title")}</p>
    <div>
    <div className={style.CompanyInfo_wrapper}>
      <Info
        icon={<HandshakeTwoToneIcon />}
        title={t("aboutDiverger.info.sectors.title")}
        list={
          <Trans
            i18nKey="aboutDiverger.info.sectors.list"
            components={{ ul: <ul />, li: <li /> }}
          />
        }
      />
      <Info
        icon={<BusinessTwoToneIcon />}
        title={t("aboutDiverger.info.offices.title")}
        list={
          <Trans
            i18nKey="aboutDiverger.info.offices.list"
            components={{ ul: <ul />, li: <li /> }}
          />
        }
      />
    </div>
    <div className={style.Numbers_grid}>
      <Number
        digit={"14"}
        text={t("aboutDiverger.number.years.text")}
      />
      <Number
        digit={"+30%"}
        text={t("aboutDiverger.number.growth.text")}
      />
      <Number
        digit={"+300"}
        text={t("aboutDiverger.number.projects.text")}
      />
      <Number
        digit={"+120"}
        text={t("aboutDiverger.number.people.text")}
      />
      <Number
        digit={"+100"}
        text={t("aboutDiverger.number.clients.text")}
      />
      <Number
        digit={"8"}
        text={t("aboutDiverger.number.products.text")}
      />
    </div>
    </div>
  </BoundedSection>
);

const Clients = ({ t }) => (
  <>
    <BoundedSection>
      <h3 className={style.Clients_pretitle}>{t("aboutDiverger.clients.preTitle")}</h3>
      <p className={style.Clients_title}>{t("aboutDiverger.clients.title")}</p>
    </BoundedSection>
    <BoundedSection className={style.WorldMap}>
        <img src={WorldMap} alt={"World Map"}/>
    </BoundedSection>
    <BoundedSection className={style.Clients_list}>
      <div>
          <h4>{t("aboutDiverger.clients.country.spain")}</h4>
          <p>Inditex</p>
          <p>Xunta de Gapcia</p>
          <p>Universidade da Coruña</p>
          <p>Enxendra</p>
          <p>Elogia</p>
          <p>Fridama</p>
          <p>Ilunion</p>
          <p>Telefónica</p>
          <p>Mapfre</p>
          <p>Cepsa</p>
          <p>Prosegur</p>
          <p>Tecnicas Reunidas</p>
          <p>Entelgy</p>
          <p>Amavir</p>
          <p>Metrovacesa</p>
          <p>Masmóvil</p>
          <p>T2Ó</p>
          <p>Yoigo</p>
          <p>Goldcar</p>
          <p>BBVA</p>
          <p>Instituto Cervantes</p>
      </div>
      <div>
        <h4>{t("aboutDiverger.clients.country.UK")}</h4>
          <p>BP</p>
      </div>
      <div>
        <h4>{t("aboutDiverger.clients.country.sweden")}</h4>
          <p>Ikea</p>
      </div>
      <div>
        <h4>{t("aboutDiverger.clients.country.france")}</h4>
          <p>SGS</p>
      </div>
      <div>
        <h4>{t("aboutDiverger.clients.country.switzerland")}</h4>
          <p>Schweppes</p>
          <p>Médicos Sin Fronteras</p>
      </div>
      <div>
        <h4>{t("aboutDiverger.clients.country.netherlands")}</h4>
          <p>Teva</p>
      </div>
      <div>
        <h4>{t("aboutDiverger.clients.country.italy")}</h4>
          <p>Italfarmaco</p>
      </div>
      <div>
        <h4>{t("aboutDiverger.clients.country.southKorea")}</h4>
          <p>Kia</p>
      </div>
      <div>
        <h4>{t("aboutDiverger.clients.country.USA")}</h4>
          <p>Denodo</p>
      </div>
    </BoundedSection>
  </>
)

const TechLogo = ({ title, img, size, parallaxDistance }) => (
  <Parallax distance={parallaxDistance} className={`${style.TechLogo} ${size}`}>
    <img src={img} alt={title} className={style.TechLogo_img} />
  </Parallax>
);

const techLogos = [
  { title: "Java", size: style.xl, img: JavaLogo, parallaxDistance: -0.025 },
  {
    title: "Apache Kafka",
    size: style.s,
    img: KafkaLogo,
    parallaxDistance: 0.025,
  },
  { title: "React", size: style.l, img: ReactLogo, parallaxDistance: -0.025 },
  { title: "Html", size: style.xs, img: HtmlLogo, parallaxDistance: 0.025 },
  { title: "CSS", size: style.m, img: CssLogo, parallaxDistance: -0.025 },
  { title: "Rails", size: style.s, img: RailsLogo, parallaxDistance: 0.025 },
  {
    title: "Docker",
    size: style.xl,
    img: DockerLogo,
    parallaxDistance: -0.025,
  },
  { title: "Rest", size: style.xs, img: RestLogo, parallaxDistance: 0.025 },
  {
    title: "GraphQl",
    size: style.xl,
    img: GraphQlLogo,
    parallaxDistance: -0.025,
  },
  { title: "gRPC", size: style.m, img: GRPCLogo, parallaxDistance: 0.05 },
  {
    title: "JavaScript",
    size: style.xl,
    img: JavaScriptLogo,
    parallaxDistance: -0.025,
  },
  {
    title: "Cucumber",
    size: style.s,
    img: CucumberLogo,
    parallaxDistance: 0.025,
  },
  {
    title: "Cypress",
    size: style.xl,
    img: CypressLogo,
    parallaxDistance: -0.025,
  },
  {
    title: "Kubernetes",
    size: style.m,
    img: KubernetesLogo,
    parallaxDistance: 0.025,
  },
  {
    title: "MongoDB",
    size: style.l,
    img: MongoDbLogo,
    parallaxDistance: -0.025,
  },
  { title: "MySql", size: style.xs, img: MySqlLogo, parallaxDistance: 0.025 },
  {
    title: "PostgreSQL",
    size: style.xl,
    img: PostgreSQlLogo,
    parallaxDistance: -0.025,
  },
];

const TechLogos = () => (
  <BoundedSection
    className={style.TechLogos}
    boundedClassName={style.TechLogos_wrapper}
  >
    <NotDesktopView>
      <Swiper
        slidesPerView={3}
        spaceBetween={8}
        loopFillGroupWithBlank={true}
        loop={true}
        freeMode={true}
        autoplay={{
          delay: 1,
          disableOnInteraction: false,
        }}
        allowTouchMove={false}
        speed={5000}
        modules={[Autoplay]}
        className="mySwiper"
      >
        {techLogos.map(({ title, img, size, parallaxDistance }) => (
          <SwiperSlide key={uuid()}>
            <TechLogo
              title={title}
              img={img}
              size={size}
              parallaxDistance={parallaxDistance}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </NotDesktopView>
    <DesktopView>
      <Masonry
        breakpointCols={7}
        className={style.TechLogos_grid}
        columnClassName={style.TechLogos_gridCol}
      >
        {techLogos.map(({ title, img, size, parallaxDistance }) => (
          <TechLogo
            key={uuid()}
            title={title}
            img={img}
            size={size}
            parallaxDistance={parallaxDistance}
          />
        ))}
      </Masonry>
    </DesktopView>
  </BoundedSection>
);

const Tech = ({ t }) => (
  <>
    <BoundedSection>
      <h3 className={style.Tech_title}>
        <CodeTwoToneIcon />
        <Trans i18nKey="aboutDiverger.tech.title" />
      </h3>
    </BoundedSection>
    <TechLogos />
    <BoundedSection className={style.Certifications}>
      <img src={PartnerPledgeLogo} alt="Microsoft Partner Pledge"/>
      <img src={InnovativePyme} alt="Pyme Innovadora"/>
      <img src={Iso14001} alt="ISO-14001"/>
      <img src={Iso9001} alt="ISO-9001"/>
    </BoundedSection>
  </>
);

const AboutDivergerPage = ({ data }) => {
  const { t } = useTranslation();
  return (
    <PageWrapper
      seoTitle={t("aboutDiverger.seo.title")}
      seoDescription={t("aboutDiverger.seo.description")}
    >
      <HeaderSection
        preTitle={t("aboutDiverger.header.preTitle")}
        title={<Trans i18nKey="aboutDiverger.header.title" />}
        description={t("aboutDiverger.header.description")}
      />
      <Slider data={data} />
      <IntroSection>
        <Trans i18nKey="aboutDiverger.intro" />
      </IntroSection>
      <IntroColumns
        icon={<AutoAwesomeTwoToneIcon/>}
        title={t("aboutDiverger.introColumns.title")}
        description={<Trans i18nKey="aboutDiverger.introColumns.description" />}
      ></IntroColumns>
      <CompanyData t={t} />
      <Clients t={t} />
      <Tech t={t} />
    </PageWrapper>
  );
};

export default AboutDivergerPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    slider1: file(relativePath: { eq: "misc/growth.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE)
      }
    }
    slider2: file(relativePath: { eq: "misc/software-development.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE)
      }
    }
    teamsImg: file(relativePath: { eq: "misc/mmuino_ddorrego.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE)
      }
    }
    awesomeQuoteImg: file(relativePath: { eq: "misc/falvarado_back.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE)
      }
    }
  }
`;
